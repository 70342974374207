<template>
    <div>
        <el-card>
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item><a @click="request">首页</a></el-breadcrumb-item>
                <el-breadcrumb-item>操作指南</el-breadcrumb-item>
            </el-breadcrumb>
            <div class="hr-20"></div>
            <div>
                <el-form label-width="0" class="ruleForm" size="small" inline @submit.native.prevent>
                    <el-form-item class="fl">
                        <el-button type="primary" plain @click="requestAdd">添加</el-button>
                    </el-form-item>
                    <el-form-item class="fl">
                        <el-button type="primary" plain @click="requestRefresh" icon="el-icon-refresh">刷新</el-button>
                    </el-form-item>
                    <el-form-item class="fr">
                        <el-button type="primary" @click="requestQuery" style="color: #f0f0f0">查询</el-button>
                    </el-form-item>
                    <el-form-item class="fr">
                        <el-input v-model="searchKeyword" clearable placeholder="请输入关键字"
                                  @keyup.enter.native="requestQuery"></el-input>
                    </el-form-item>
                </el-form>
            </div>

            <el-table :data="tableData" ref="multipleTable">
                <el-table-column prop="title" label="指南标题" show-overflow-tooltip align="left" min-width="320px"/>

                <el-table-column label="创建时间" width="120">
                    <template slot-scope="scope">{{ scope.row.createTime | formatDate}}</template>
                </el-table-column>

                <el-table-column label="修改时间" width="120" >
                    <template slot-scope="scope">{{ scope.row.modifiedTime | formatDate}}</template>
                </el-table-column>
                <el-table-column prop="type" label="操作" align="center" width="200" fixed="right">
                    <!-- eslint-disable-next-line -->
                    <template slot-scope="scope">
                        <el-button size="mini" type="primary" plain @click="requestEdit(scope.row)">编辑</el-button>
                        <el-button plain type="danger" size="mini" @click="requestDelete(scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>

            <el-pagination background layout="prev, pager, next" :total="total" :page-size="pageSize" :current-page="page"
                           @current-change="requestPage" id="pagination"/>
            <span style="float: left;margin: -40px 0 0 0;">共计{{total}}条数据</span>
        </el-card>
    </div>
</template>

<script>
export default {
    data() {
        return {
            page: this.$route.query.page ||1,
            pages: 0,
            pageSize: 10,
            total: 1,
            form: {
                columnId: 0,
                title: '',
                type: '',
                status: 0,
                content: '',
            },
            editForm: {},
            tableData: [],
            searchKeyword: '',
            createForm: {
                id: 0,
                title: "",
                content: "",
            },
            exsleData: {
                columnId: 0,
                content: "",
                title: "",
            },
            columnId: 0,
            columnData: {
                name: '',
                status: 0,
            },
            idData: "",
        }
    },
    created() {
        this.getData();
    },
    methods: {
        request() {
            let newPage = this.$router.resolve({
                name: 'EntryStoma',
            })
            window.open(newPage.href, '_blank')
        },
        getData() {
            this.$axios.get('/column/page').then(response => {
                if (response.data.status === 200) {
                    response.data.data.records.forEach(array => {
                        if (array.name === '操作指南') {
                            this.form.columnId = array.id;
                            this.$axios.get('/article/column', {
                                params: {
                                    columnId: array.id,
                                    current: this.$route.query.page ||1,
                                    size: this.pageSize
                                }
                            }).then(response => {
                                this.tableData = response.data.data.records;
                                this.total = response.data.data.total;
                            }).catch(error => {
                                console.error(error)
                            })
                        }
                    })
                } else {
                    this.$root.warn("数据加载失败");
                    console.log(response.data.status)
                }
            }).catch(error => {
                console.error(error)
            })
        },
        //  刷新
        requestRefresh() {
            this.total = 0;
            this.getData();
            this.searchKeyword = '';
        },
        //  点击添加
        requestAdd() {
            this.$router.push({name: 'add-guide'})
        },
        /**
         * 点击编辑
         * @param rowobject
         */
        requestEdit(row) {
            let self = this;
            self.$router.push({name: 'edit-guide', query: {id: row.id,page:this.page}})
        },
        /**
         * 删除
         * @param rowobject
         */
        requestDelete(row) {
            this.$confirm('确定删除该指南?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$axios.delete('/article?id=' + row.id).then(response => {
                    if (response.data.status === 200) {
                        this.createLoading = false;
                        this.$root.success("删除成功");
                        this.getData();
                    } else {
                        this.$root.warn("删除失败")
                    }
                }).catch(error => {
                    console.error(error)
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        /**
         *  分页
         * @param value Number
         */
        requestPage(value) {
            this.page = value
            let self = this
            self.$axios.post('/article/condition', {
                title: this.searchKeyword,
                columnId: self.form.columnId,
                current: value,
                size: self.pageSize
            }).then(function (response) {
                self.tableData = response.data.data.records;
                self.total = response.data.data.total;
            }).catch(error => {
                console.error(error)
            })
        },

        //  查询
        requestQuery() {
            this.total =1
            this.$axios.post('/article/condition', {
                columnId: this.form.columnId,
                title: this.searchKeyword,
                current: 1,
                size: this.pageSize
            }).then(response => {
                this.tableData = response.data.data.records;
                this.total = response.data.data.total;
                if (this.searchKeyword.length === 0) {
                    this.getData();
                    this.total = 1
                }
            }).catch(error => {
                console.error(error)
            })
        },

        back() {
            this.$router.back()
        },


    }
}
</script>

<style scoped>
.div-card {
    margin: 40px;
}

#card {
    margin: auto;
    width: 90%;
}

.card-span {
    margin: 0 16px;
    float: right;
}

.input {
    margin: 32px;
}

#btn {
    margin: 16px;
}

#pagination {
    margin: 40px 0 16px 0;
}
</style>